.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.overlay {
  position: absolute;
  background-color: var(--color1);
  width: 100%;
  height: 100%;
  z-index: 10;
  animation: reveal 1s ease-out;
  opacity: 0;
  transition: background-color 0.25s ease-out, opacity 0.25s ease-out, transform 0.25s ease-in;
}

@keyframes reveal {
  from { 
    opacity: 1; 
  }
  to {
    opacity: 0; 
  }
}

.overlay.cover {
  opacity: 1;
}
