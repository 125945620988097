.ui-controls-component {
  background: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.5));
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 150px 30px 30px;
  width: 100vw;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  z-index: 10;

  .slider-wrapper {
    background-color: #FFFFFF;
    padding: 0 1.25rem 0 0;
    border-radius: .25rem;
    width: 260px;
    height: 50px;
    z-index: 20;
    margin-right: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .label {
      margin: 0 1.25rem 0 1rem;
    }
  }

  .btn {
    &.btn-primary {
      color: #000000;
      background-color: #FFFFFF;
      border-color: #FFFFFF;
      margin-right: 10px;
      padding: 0.75rem 1rem;
  
      &:hover {
        background-color: var(--color1);
        border-color: var(--color1);
      }

      &.focus,
      &:focus {
        box-shadow: 0 0 2px 0.2rem var(--color1);
      }

      &:not(:disabled):not(.disabled):active {
        border-color: #FFFFFF;
      }
    }
  }

  .wrapper {
    background-color: red !important;
  }
}
